<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item>
          <el-input
              v-model="formInline.user"
              placeholder="请输入-标题内容"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formInline.region" placeholder="请选择-业务类型">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="formInline.user" placeholder="发行编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
              v-model="formInline.user"
              placeholder="请输入结算方名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
              v-model="formInline.user"
              placeholder="请输入-发行方"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formInline.region" placeholder="上架时间">
            <el-option label="上架时间" value="shanghai"></el-option>
            <el-option label="下架时间" value="beijing"></el-option>
          </el-select>
          <el-date-picker
              v-model="value1"
              end-placeholder="结束日期"
              prefix-icon="none"
              range-separator="~"
              start-placeholder="开始日期"
              type="daterange"
              unlink-panels
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">60</span>条信息
        </div>
        <div class="op-extra">
          <icon-button icon="add-select" @click.native="dialogFormVisible = true"/>
          <icon-button icon="share"/>
        </div>
      </div>
      <div>
        <el-table
            :data="tableData"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="标题" prop="name" width="250">
          </el-table-column>
          <el-table-column label="发行编号" prop="name" width="250">
          </el-table-column>
          <el-table-column label="发行方" prop="name" width="250">
          </el-table-column>
          <el-table-column label="触发事件" prop="name" width="250">
          </el-table-column>
          <el-table-column label="适用业务类型" prop="name" width="180">
          </el-table-column>
          <el-table-column label="结算方名称" prop="name" width="250">
          </el-table-column>
          <el-table-column label="上架时间" prop="name" width="180">
          </el-table-column>
          <el-table-column label="下架时间" prop="name" width="180">
          </el-table-column>
          <el-table-column label="发行量" prop="name" width="180">
          </el-table-column>
          <el-table-column label="领取量" prop="name" width="180">
          </el-table-column>
          <el-table-column label="使用量" prop="name" width="180">
          </el-table-column>
          <el-table-column label="状态" prop="name" width="180">
          </el-table-column>
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              prop="name"
              width="100px"
          >
            <template>
              <el-button size="small" style="font-size: 14px" type="text"
              >详情
              </el-button
              >
              <el-button size="small" style="font-size: 14px" type="text"
              >更多
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
              :current-page="1"
              :page-size="100"
              :page-sizes="[100, 200, 300, 400]"
              :total="400"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出的表单 -->
    <el-dialog :append-to-body="true" :visible.sync="dialogFormVisible" title="新建规则">

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">下一步</el-button>
        <el-button @click="dialogFormVisible = false">上一步</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "offline-manage",
  data() {
    return {
      dialogFormVisible: false,//控制弹窗层显示
      value1: "",
      formInline: {
        user: "",
        region: "",
      },
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        }
      ],
    };
  },
  methods: {
    goAdd() {
      console.log("add");
    },
    onSubmit() {
      console.log("submit!");
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less" scoped>
</style>
